import React from 'react';

import { Section } from 'src/components/molecules/Section';
import { CommonLogos } from 'src/components/organisms/CommonLogos';

export default function LegacySection({ customData }) {
  customData = JSON.parse(customData);

  return (
    <Section {...customData?.flags}>
      <CommonLogos centered />
    </Section>
  );
}
