import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Link } from 'src/containers/Link';
import { Logo as LogoNew } from 'src/containers/Logo.new';
import { InlineList } from 'src/components/molecules/InlineList';
export const CommonLogos = ({ white, centered }) => {
  const data = useStaticQuery(graphql`
    query {
      lemonade: file(relativePath: { eq: "logos/lemonade_logo.svg" }) {
        ...LogoImageDataNoSharp
      }
      n26: file(relativePath: { eq: "logos/n26_logo.svg" }) {
        ...LogoImageDataNoSharp
      }
      adobe: file(relativePath: { eq: "logos/adobe_logo.svg" }) {
        ...LogoImageDataNoSharp
      }
      hca: file(relativePath: { eq: "logos/hca_logo.png" }) {
        ...LogoImageData
      }
      orange: file(relativePath: { eq: "logos/orange_logo.png" }) {
        ...LogoImageData
      }
    }
  `);
  return (
    <InlineList centered={centered} spacious>
      <li>
        <Link to="/customers/lemonade/">
          <LogoNew alt="Lemonade" data={data.lemonade} grey={!white} white={white} small />
        </Link>
      </li>
      <li>
        <Link to="/customers/n26/">
          <LogoNew alt="N26" data={data.n26} grey={!white} white={white} small />
        </Link>
      </li>
      <li>
        <Link to="/customers/">
          <LogoNew alt="Adobe" data={data.adobe} grey={!white} white={white} small />
        </Link>
      </li>
      <li>
        <Link to="/customers/">
          <LogoNew alt="HCA" data={data.hca} grey={!white} white={white} small />
        </Link>
      </li>
      <li>
        <Link to="/customers/orange/">
          <LogoNew alt="Orange" data={data.orange} grey={!white} white={white} small />
        </Link>
      </li>
    </InlineList>
  );
};
